import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Text from '../components/Text';


class Map extends React.Component {
	state = {
        totemId: undefined,
        storeId: undefined,
        store: undefined,
        mapLoaded: false,
    }

    componentDidMount() {
        const { storeId, totemId } = this.props.params;
        this.setState({storeId, totemId});
        fetch('/json/stores.json')
            .then(data => data.json())
            .then(stores => stores.forEach(store => {
                if (store.location === storeId) {
                    this.setState({store});
                }
            })
        );
	}

    onMapLoad() {
        this.setState({mapLoaded: true});
    }

    render() {
        const { mapLoaded, store, storeId, totemId } = this.state;
        if (store !== undefined) {
            const block = storeId.split('.')[0]
            const imgUrl = '/img/maps/' + totemId + '/' + block + '/' + totemId + '-' + storeId + '.svg';
            return (
                <div className='map'>
                    <Text big bold>{store.name}</Text>
                    <Text small>
                        {store.store && `Loja ${store.store}`} 
                        {store.stand && `Loja ${store.stand}`} 
                        {store.kiosk && `Loja ${store.kiosk}`} 
                        - Bloco {store.block}
                    </Text>
                    {!mapLoaded && <div className="loader"></div>}
                    <img src={imgUrl} width="100%" height="auto" alt={store.name} onLoad={this.onMapLoad.bind(this)} />
                </div>
            );
        }
        return null;
    }
}

function MapWrapper(props) {
    return <Map {...props} navigate={useNavigate()} params={useParams()} />
}

export default MapWrapper;