import React from "react";

import Text from './Text';

import './StoreNotFound.css';


class StoreNotFound extends React.Component {
    render() {
        return (
            <div className='store-not-found'>
                <Text big bold>Ops, loja não encontrada!</Text>
                <Text>Certifique-se que o nome está correto e tente novamente!</Text>
            </div>
        );
    }
}

export default StoreNotFound;