import React from "react";

import './Text.css';

class Text extends React.Component {
    render() {
        const { small, big, bigger, bold, inline, children } = this.props;
        const weightClass = bold ? 'text-bold' : 'text-regular';
        const sizeClass = small ? 'text-small' : big ? 'text-big' : bigger ? 'text-bigger' : 'text-normal';
        if (inline) {
            return <span className={weightClass + ' ' + sizeClass}>{children}</span>;
        } else {
            return <p className={weightClass + ' ' + sizeClass}>{children}</p>;
        }
    }
}

export default Text;