import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Header.css';

import back from '../assets/images/back.png';
import logo from '../assets/images/logo.png';

class Header extends React.Component {
    onBackClick() {
        this.props.navigate(-1);
    }

    render() {
		return (
			<header>
                <div className="container">
                    {window.location.href.indexOf('/rotas/') !== -1 && <img src={back} className='back' alt='Voltar' onClick={this.onBackClick.bind(this)} />}
                    <img src={logo} className='logo' alt='Uptown' />
                </div>
            </header>
        );  
    }
}

function HeaderWrapper(props) {
    return <Header {...props} navigate={useNavigate()} />
}

export default HeaderWrapper;