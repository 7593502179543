import React from "react";

import './Select.css';

class Select extends React.Component {
    render() {
        const { options, onChange } = this.props;
		return (
            <div class="select-wrapper">
                <select onChange={onChange}>
                    <option>Selecione...</option>
                    {options}
                </select>
            </div>
        );
    }
}

export default Select;