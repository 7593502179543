import React from "react";
import { useNavigate } from "react-router-dom";

import Select from '../components/Select';
import Text from '../components/Text';

class Totems extends React.Component {
    state = {
        totems: []
    }

    componentDidMount() {
        fetch('/json/totems.json')
            .then(data => data.json())
            .then(totems => this.setState({totems}));
    }

    onChangeTotem(e) {
        this.props.navigate(`/totens/${e.target.value}`);
    }

    render() {
        const options = [];
        this.state.totems.forEach(totem => { options.push(<option value={totem.id}>{totem.name}</option>)});
        return (
            <section>
                <Text bold big>Bem vindo ao Uptown Barra!</Text>
                <Text>Selecione o ponto de origem:</Text>
                <Select options={options} onChange={this.onChangeTotem.bind(this)} />
            </section>
        );
    }
}

function TotemsWrapper(props) {
    let navigate = useNavigate();
    return <Totems {...props} navigate={navigate} />
}

export default TotemsWrapper;