import React from "react";

import './TextInput.css';

class TextInput extends React.Component {
    render() {
        const { onChange, value, placeholder } = this.props;
        return <input type='text' value={value} placeholder={placeholder} onChange={onChange} />;
    }
}

export default TextInput;