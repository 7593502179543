import React from "react";

import Text from './Text';

import './StoreCard.css';

import arrows from '../assets/images/arrows.png';


class StoreCard extends React.Component {
    render() {
        const { store, onClick } = this.props;
        const tokens = store.location.split('.');
        let location = '';
        let block = '';
        if (tokens.length === 2) {
            block = tokens[0].replace('BL', '');
            if (tokens[1].indexOf('ST') !== -1) {
                location = 'Stand ' + tokens[1].replace('ST', '');
            }
            else if (tokens[1].indexOf('QU') !== -1) {
                location = 'Quiosque ' + tokens[1].replace('QU', '');
            }
            else {
                location = 'Loja ' + tokens[1].replace('LJ', '');
            }
            
            location += ' Bloco ' + block;
        }
        let categories = '';
        store.categories.forEach(category => { 
            if (categories !== '') categories += ', ';
            categories += category;
        });
        return (
            <div className='store-card'>
                <div className='info'>
                    <div className='text'>
                        <div className='block'>
                            <Text big bold>
                                {store.name}
                            </Text>
                            <Text small>{categories}</Text>
                        </div>
                        <Text>{location}</Text>
                    </div>
                    <div className="">
                        <div className="image" style={{ backgroundImage: `url(${store.image})`}} />
                        {store.map &&
                        <div className={`button bl${block}`} onClick={onClick}>
                            <Text small bold>Ver trajeto</Text>
                            <img src={arrows} alt='Ver trajeto' />
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default StoreCard;