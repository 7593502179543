import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from '../components/Button';
import Modal from '../components/Modal';
import StoreCard from '../components/StoreCard';
import StoreNotFound from '../components/StoreNotFound';
import Text from '../components/Text';
import TextInput from '../components/TextInput';


class Totem extends React.Component {
    state = {
        block: undefined,
        blocks: [],
        categories: [],
        category: undefined,
        search: '',
        showBlocks: false,
        showCategories: false,
        showStores: false,
        storesByBlock: [],
        storesByCategory: [],
        totem: undefined,
        totems: [],
        totemId: undefined,
    }

    componentDidMount() {
        const { totemId } = this.props.params;
        this.setState({totemId});
        fetch('/json/totems.json')
            .then(data => data.json())
            .then(totems => {
                this.setState({totems});
                const totem = totems.find(totem => totem.id === totemId);
                if (totem !== undefined) {
                    this.setState({totem});
                }                
            });
        fetch('/json/blocks.json')
            .then(data => data.json())
            .then(blocksNum => {
                const blocks = [];
                blocksNum.forEach(num => blocks.push('Bloco ' + num));
                this.setState({blocks})
            });
        fetch('/json/categories.json')
            .then(data => data.json())
            .then(categories => this.setState({categories}));
        fetch('/json/stores.json')
            .then(data => data.json())
            .then(stores => {
                const storesByBlock = {};
                const storesByCategory = {};
                stores.forEach(store => { 
                    store.categories.forEach(category => {
                        if (!storesByCategory.hasOwnProperty(category)) {
                            storesByCategory[category] = [];
                        }
                        storesByCategory[category].push(store);
                    })
                    if (!isNaN(store.block)) {
                        if (!storesByBlock.hasOwnProperty(store.block)) {
                            storesByBlock[store.block] = [];
                        }
                        storesByBlock[store.block].push(store);
                    }

                });
                this.setState({storesByBlock, storesByCategory});
            });
    }

    getStores() {
        const { block, category, search, storesByBlock, storesByCategory } = this.state;

        const categories = category !== undefined ? [category] : Object.getOwnPropertyNames(storesByCategory);
        const blocks = block !== undefined ? [block] : Object.getOwnPropertyNames(storesByBlock); 
        const storeLis = [];

        (block ? blocks : categories).sort().forEach(item => {
            const lis = [];
            let stores = [];

            if (block && blocks.length > 0) {
                stores = storesByBlock[item.replace(/\D/g, '')];
            } else {
                stores = storesByCategory[item];
            }

            if (stores && stores.length) {
                stores.forEach((store, idx) => {
                    if ((search !== '' && store.name.toUpperCase().indexOf(search.toUpperCase()) !== -1) || search === '') { 
                        lis.push(<StoreCard key={idx} store={store} onClick={() => this.onStoreClick(store.location)} />);
                    }
                });

                if (lis.length > 0) {
                    storeLis.push(
                        <div key={item}>
                            {search === '' && <Text big bold>{item}</Text>}
                            <ul>{lis}</ul>
                        </div>
                    );
                }
            }
        });
        return storeLis;
    }

    onBlockClick(e) {
        this.setState({showStores: false, showBlocks: false, showCategories: false, search: '', block: e.target.childNodes[0].data, category: undefined});
    }

    onBlocksClick() {
        this.setState({showStores: false, showBlocks: true, showCategories: false, search: '', block: undefined, category: undefined});
    }

    onCategoryClick(e) {
        this.setState({showStores: false, showBlocks: false, showCategories: false, search: '', block: undefined, category: e.target.childNodes[0].data});
    }

    onCategoriesClick() {
        this.setState({showStores: false, showBlocks: false, showCategories: true, search: '', block: undefined, category: undefined});
    }

    onCloseClick() {
        this.setState({showStores: false, showBlocks: false, showCategories: false});
    }

    onStoreClick(location) {
        const { totemId } = this.state;
        this.props.navigate(`/rotas/${totemId}/${location}`);
    }

    onStoresClick() {
        this.setState({showStores: true, showBlocks: false, showCategories: false, search: '', block: undefined, category: undefined});
    }

    onStoreSearchChange(e) {
        this.setState({search: e.target.value, showStores: false, showBlocks: false, showCategories: false, block: undefined, category: undefined});
    }

    render() {
        const { block, blocks, categories, category, showBlocks, showCategories, showStores, search, totem } = this.state;
        const storeLis = this.getStores();
        return (
            <section>
                <Text big bold>Bem vindo ao Uptown Barra!</Text>
                <Text>Você está em <Text bold inline>{totem && totem.name}</Text></Text>
                <Text>Selecione o seu destino na lista contendo todas as lojas ou, se preferir, filtre por nome, categoria ou bloco.</Text>
                <p><TextInput value={search} onChange={this.onStoreSearchChange.bind(this)} /></p>
                <p>
                    <Button text='Ver Todas as Lojas' active={showStores} onClick={this.onStoresClick.bind(this)} />
                    <Button text='Categorias' active={category} onClick={this.onCategoriesClick.bind(this)} />
                    <Button text='Blocos' active={block} onClick={this.onBlocksClick.bind(this)} />
                </p>
                {(showStores || search.length > 0 || category !== undefined || block !== undefined) && storeLis.length > 0 ? storeLis : search.length > 0 && <StoreNotFound />}
                {showCategories && <Modal items={categories} title='Categorias' onClick={this.onCategoryClick.bind(this)} onClose={this.onCloseClick.bind(this)} />}
                {showBlocks && <Modal items={blocks} title='Blocos' onClick={this.onBlockClick.bind(this)} onClose={this.onCloseClick.bind(this)} />}
            </section>
        );
    }
}

function TotemWrapper(props) {
    return <Totem {...props} navigate={useNavigate()} params={useParams()} />
}

export default TotemWrapper;