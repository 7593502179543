import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';

import  Header from './components/Header';

import Map from './pages/Map';
import Totem from './pages/Totem';
import Totems from './pages/Totems';


function App() {
	return (
    	<BrowserRouter>
			<Header />
			<main className="container">
				<Routes>
					<Route path="/rotas/:totemId/:storeId" element={<Map />} />
					<Route path="/totens/:totemId" element={<Totem />} />
					<Route path='/totens' element={<Totems />} />
				</Routes>
			</main>
    	</BrowserRouter>
  	);
}

export default App;
