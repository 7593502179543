import React from "react";

import './Button.css';

class Button extends React.Component {
    render() {
        const { text, active, onClick } = this.props;
        return <input type='button' value={text} className={active ? 'active' : ''} onClick={onClick}/>;
    }
}

export default Button;