import React from "react";

import Text from './Text';
import './Modal.css';

import close from '../assets/images/close.png';


class Modal extends React.Component {
    render() {
        const { items, onClick, onClose, title } = this.props;
        const lis = [];
        items.forEach((item, idx) => { lis.push(
            <li key={idx} onClick={onClick}><Text bold>{item}</Text></li>
        )});
        return (
            <section className='modal'>
                <Text bigger bold>{title}</Text>
                <img src={close} onClick={onClose} alt='Fechar' />
                <ul>
                    {lis}
                </ul>
            </section>
        );
    }
}

export default Modal;